const Loading = () => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col content-center items-center">
      <h1 className="text-4xl font-bold text-red-600 mt-40">PORT<span className="text-gray-900"> | KEY</span></h1>
      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-20 w-20 m-20"></div>
      <h2 className="text-center text-white text-xl font-semibold mb-20">Loading...</h2>
    </div>
  )
}

export default Loading
