// React
import { useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
// Auth
import { useAuth0 } from "@auth0/auth0-react"
// Store
import { useStateValue } from "../store/StateProvider"
// Utils
import { getNameFromTags, fetchDELETE, fetchGET, wait } from "../util"
// Icons
import { CubeIcon, EyeIcon, EyeOffIcon } from "@heroicons/react/solid"
import { TrashIcon } from "@heroicons/react/outline"
// Components
import Loading from "../components/Loading"
import Modal from "../components/Modal"
import Alert from "../components/Alert"
import Header from "../components/Header"
import Error from "../components/Error"

const Bastion = () => {
  const { user } = useAuth0()
  const { id } = useParams()
  const history = useHistory()
  const [{ token }] = useStateValue()
  // State
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [bastion, setBastion] = useState(null)
  const [openDelete, setOpenDelete] = useState(false)

  // Fetch Bastion
  useEffect(() => {
    const fetchBastion = async () => {
      setIsLoading(true)
      try {
        const bs = await fetchGET(`users/${user.email}/bastions/${id}`, token)
        if (bs) {
          setBastion(bs)
        } else {
          setError("Bastion not found")
        }
      } catch (err) {
        setError(err)
      }
      setIsLoading(false)
    }
    fetchBastion()
  }, [id, token, user.email])

  const handleDeleteBastion = async () => {
    try {
      setIsLoading(true)
      const deleteBastionRes = await fetchDELETE(
        `users/${user.email}/bastions/${bastion.id}`,
        token
      )
      if (deleteBastionRes.error) {
        setError(deleteBastionRes.error)
      } else {
        let getBastion
        do {
          getBastion = await fetchGET(
            `users/${user.email}/bastions/${bastion.id}`,
            token
          )
          if (getBastion) {
            await wait(7000)
          }
        } while (getBastion)
        setIsLoading(false)
        history.push("/")
      }
    } catch (err) {
      console.log("ERROR: ", err)
      setError("Failed to delete Bastion")
      setIsLoading(false)
    }
  }

  if (error) return <Error error={error} />
  if (isLoading || !bastion) return <Loading />
  return (
    <>
      <Modal open={openDelete} setOpen={setOpenDelete} title="WARNING">
        <Alert
          setOpen={setOpenDelete}
          description={`Are you sure you want to delete bastion: ${bastion.id}`}
          onSubmit={handleDeleteBastion}
        />
      </Modal>
      <Header title="Bastion" />
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-5 mb-5">
        <div className="px-4 py-5 sm:px-6 flex items-center">
          <div className="flex-grow">
            <h3 className="text-lg leading-6 font-medium text-indigo-600">
              {bastion.id}
            </h3>
            <div className="flex items-center text-sm text-gray-500 -ml-1">
              <CubeIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <p>{bastion.accountNumber}</p>
            </div>
          </div>
          <button onClick={() => setOpenDelete(true)}>
            <TrashIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400 hover:text-indigo-400"
              aria-hidden="true"
            />
          </button>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <TableRow title="Name">{bastion.name}</TableRow>
            <TableRow title="Public Ip Address">
              {bastion.publicIpAddress}
            </TableRow>
            <TableRow title="Vpc Id">{bastion.vpcId}</TableRow>
            <TableRow title="Subnet Id">{bastion.subnetId}</TableRow>
            <TableRow title="UUID">{bastion.uuid}</TableRow>
            <TableRow title="Security Groups">
              <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                {bastion.securityGroups.map((sg) => (
                  <ListItem title={sg.id} subTitle={sg.name} />
                ))}
              </ul>
            </TableRow>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-2 sm:px-6">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Resources
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                SSH Commands
              </dd>
            </div>
            {flatResources(bastion.resources).map((r) => (
              <Resource username={bastion.username} resource={r} />
            ))}
          </dl>
        </div>
      </div>
    </>
  )
}

export default Bastion

const TableRow = ({ title, children }) => (
  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-2 sm:px-6">
    <dt className="text-sm font-medium text-gray-500 break-all">{title}</dt>
    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
      {children}
    </dd>
  </div>
)

const ResourceRow = ({ resource, children }) => (
  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-2 sm:px-6">
    <div>
      <dt className="text-sm font-medium text-gray-500 break-all">
        {resource.id}
      </dt>
      <p className="text-xs font-bold text-gray-800 break-all">
        {getNameFromTags(resource.tags)}
      </p>
      <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
        {resource.resourceType}
      </p>
    </div>
    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
      {children}
    </dd>
  </div>
)
const Resource = ({ username, resource }) => {
  const [showPass, togglePass] = useState(false)
  return (
    <ResourceRow resource={resource} key={resource.id}>
      <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
        {resource.userPassword ? (
          <>
            <ListItem title="Database User" subTitle={username} />
            <ListItem
              title="Database Password"
              subTitle={
                showPass ? (
                  <>
                    <pre style={{ display: "inline-block" }}>
                      {resource.userPassword}
                    </pre>
                    <EyeOffIcon
                      style={{
                        width: 18,
                        display: "inline-block",
                        marginLeft: 20,
                      }}
                      onClick={() => togglePass((s) => !s)}
                    />
                  </>
                ) : (
                  <>
                    <pre style={{ display: "inline-block" }}>
                      {new Array(resource.userPassword.length)
                        .fill(null)
                        .map(() => (
                          <>*</>
                        ))}
                    </pre>
                    <EyeIcon
                      style={{
                        width: 18,
                        display: "inline-block",
                        marginLeft: 20,
                      }}
                      onClick={() => togglePass((s) => !s)}
                    />
                  </>
                )
              }
            />
          </>
        ) : null}
        {resource.sshCommands.map((command) =>
          command.steps.map((step) => (
            <ListItem title={step.name} subTitle={step.value} />
          ))
        )}
      </ul>
    </ResourceRow>
  )
}

const ListItem = ({ title, subTitle, tag, key = { title } }) => (
  <li key={key} className="py-1">
    <div className="mx-3">
      <div className="flex items-center justify-between">
        <p className="text-xs font-medium text-gray-900">{title}</p>
        <div className="ml-2 flex-shrink-0 flex">
          <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            {tag}
          </p>
        </div>
      </div>
      <p className="text-xs text-gray-500">{subTitle}</p>
    </div>
  </li>
)

const flatResources = (resources) => {
  const result = []
  Object.keys(resources).forEach((key) => {
    result.push(...resources[key])
  })
  return result
}
