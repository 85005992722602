import React from 'react'
import { Switch, Route } from 'react-router-dom';

import Dashboard from './pages/Dashboard';
import Accounts from './pages/Accounts';
import Account from './pages/Account';
import Bastions from './pages/Bastions';
import Bastion from './pages/Bastion';
import SSHKeys from './pages/SSHKeys'

const Routes = () => {
  return (
    <Switch>
      <Route exact from="/" render={props => <Dashboard {...props} />} />
      <Route exact path="/accounts" render={props => <Accounts {...props} />} />
      <Route exact path="/accounts/:id" render={props => <Account {...props} />} />
      <Route exact path="/bastions" render={props => <Bastions {...props} />} />
      <Route exact path="/bastions/:id" render={props => <Bastion {...props} />} />
      <Route exact path="/sshkeys" render={props => <SSHKeys {...props} />} />
    </Switch>
  )
}

export default Routes
