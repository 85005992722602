// Utils
import { capitalizeFirstLetter } from '../../util'

const CheckboxWithLabel = ({name, id=name, label=capitalizeFirstLetter(name), checked, onChange }) => {
  return (
    <div className="flex items-center mt-2">
      <input
        id={id}
        name={name}
        type="checkbox"
        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
        onChange={onChange}
        checked={checked}
      />
      <label htmlFor={id} className="ml-2 block text-sm text-gray-900">
        {label}
      </label>
    </div>
  )
}

export default CheckboxWithLabel
