// React
import { useState } from 'react'
// Components
import InputWithLabel from './InputWithLabel'
import CheckboxWithLabel from './CheckboxWithLabel'

const UpdateSSHKey = ({ value: v = '', name: n = '', isDefault: d = false, sshKeyId, title, buttonText='Submit', onCancel, onSubmit}) => {
  const [name, setName] = useState(n)
  const [value, setValue] = useState(v)
  const [isDefault, setIsDefault] = useState(d)
  const handleNameChange = (e) => setName(e.target.value)
  const handleValueChange = (e) => setValue(e.target.value)
  const handleIsDefaultChange = (e) => setIsDefault(e.target.checked)
  return (
    <div className="p-4">
      {title ? <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">{title}</h3> : ''}
      <InputWithLabel name="name" placeholder="Name your ssh key" value={name} onChange={handleNameChange} />
      <div className='mt-2'/>

      <InputWithLabel name="value" placeholder="ssh-rsa ABC..." value={value} onChange={handleValueChange} />
      <CheckboxWithLabel name="isDefault" label="Default" checked={isDefault} onChange={handleIsDefaultChange} />
      <div className="mt-2 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button
          type="button"
          className={name && value ?
            "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm" :
            "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 text-base font-medium text-white sm:col-start-2 sm:text-sm cursor-default"
          }
          onClick={() => onSubmit({name, value, isDefault, sshKeyId})}
          disabled={name && value ? false : true}

        >
          {buttonText}
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default UpdateSSHKey
