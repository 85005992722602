import { SearchIcon } from '@heroicons/react/solid'

const SearchInput = ({onChange}) => {
  return (
    <div className="mt-1 relative rounded-md shadow-sm">
      <input
        type="search"
        name="search"
        id="search"
        className="focus:ring-gray-500 focus:border-gray-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
        placeholder="Search"
        onChange={onChange}
      />
      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
    </div>
  )
}

export default SearchInput
