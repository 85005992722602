// React 
import { useState, useEffect } from 'react'
// Icons
import { PlusIcon } from '@heroicons/react/solid'
// Auth
import { useAuth0 } from '@auth0/auth0-react';
// Store
import { useStateValue } from '../store/StateProvider'
import { SET_SSHKEYS } from '../store/actions'
// Utils
import { fetchGET, fetchPOST, fetchPATCH, fetchDELETE } from '../util'
// Components
import Loading from '../components/Loading'
import SSHKey from '../components/SSHKey'
import Modal from '../components/Modal'
import UpdateSSHKey from '../components/Form/UpdateSSHKey'
import Alert from '../components/Alert'
import Header from '../components/Header'
import Error from '../components/Error'

export default function SSHKeys() {
  // Auth
  const { user } = useAuth0();
  // Store
  const [{ sshKeys, token }, dispatch] = useStateValue();
  // State
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [openUpdateSSHKey, setOpenUpdateSSHKey] = useState(false)
  const [updateSSHKeyTitle, setUpdateSSHKeyTitle] = useState('')
  const [name, setName] = useState('')
  const [value, setValue] = useState('')
  const [isDefault, setIsDefault] = useState(false)
  const [sshKeyId, setSSHKeyId] = useState(null)
  const [openDelete, setOpenDelete] = useState(false)
  const [sshKeyToDelete, setSSHKeyToDelete] = useState(null)

  // Fetch SSH Keys
  useEffect(() => {
    const fetchSSHKeys = async () => {
      setIsLoading(true)
      try {
        const keys = await fetchGET(`users/${user.email}/sshkeys`, token)
        dispatch({
          type: SET_SSHKEYS,
          payload: keys
        })
      } catch (err) {
        console.log("ERROR: ", err)
        setError("Failed to fetch ssh keys")
      }
      setIsLoading(false)
    }
    fetchSSHKeys()
  }, [dispatch, token, user.email])

  // Handle Add SSH Key (create)
  const handleAddSSHKey = () => {
    resetSSHKey()
    setUpdateSSHKeyTitle('Create SSH Key')
    setOpenUpdateSSHKey(true)
  }
  // Handle Edit SSH Key (update)
  const handleEditSSHKey = (sk) => {
    setName(sk.name)
    setValue(sk.value)
    setIsDefault(sk.isDefault)
    setSSHKeyId(sk.sshKeyId)
    setUpdateSSHKeyTitle('Update SSH Key')
    setOpenUpdateSSHKey(true)
  }
  // Handle Open Alert Delete SSH Key
  const handleOpenDelete = (sk) => {
    setSSHKeyToDelete(sk)
    setOpenDelete(true)
  }
  const handleCancelDeleteSSHKey = () => {
    setSSHKeyToDelete(null)
    setOpenDelete(false)
  }
  // Handle Delete SSH Key (delete)
  const handleDeleteSSHKey = async () => {
    setIsLoading(true)
    try {
      await fetchDELETE(`users/${user.email}/sshkeys/${sshKeyToDelete.sshKeyId}`, token)
      await fetchSSHKeys()
    } catch (err) {
      console.log("ERROR: ", err)
      setError("Failed to delete SSH Key")
    }
    setIsLoading(false)
  }
  // Handle Cancel 'Update' SSH Key (create/update)
  const handleCancelUpdateSSHKey = () => setOpenUpdateSSHKey(false)
  // Hanlde Submit 'Update' SSH Key (create/update)
  const handleSSHKeySubmit = async ({ name, value, isDefault, sshKeyId }) => {
    setIsLoading(true)
    try {
      const body = { name, value, isDefault }
      if (sshKeyId) {
        await fetchPATCH(`users/${user.email}/sshkeys/${sshKeyId}`, token, body)
      } else {
        await fetchPOST(`users/${user.email}/sshkeys`, token, body)
      }
      await fetchSSHKeys()
      resetSSHKey()
      setOpenUpdateSSHKey(false)
    } catch (err) {
      console.log("ERROR: ", err)
      setError("Failed to Update ssh key")
    }
    setIsLoading(false)
  }

  // Fetch SSH Keys 
  const fetchSSHKeys = async () => {
    const newKeys = await fetchGET(`users/${user.email}/sshkeys`, token)
    dispatch({
      type: SET_SSHKEYS,
      payload: newKeys
    })
  }
  // Reset SSH Key State
  const resetSSHKey = () => {
    setName('')
    setValue('')
    setIsDefault(false)
    setSSHKeyId(null)
  }

  if (isLoading) return <Loading />
  if (error) return <Error error={error} />

  return (
    <>
      <Modal open={openUpdateSSHKey} setOpen={setOpenUpdateSSHKey} title={updateSSHKeyTitle}>
        <UpdateSSHKey
          name={name}
          value={value}
          isDefault={isDefault}
          sshKeyId={sshKeyId}
          onCancel={handleCancelUpdateSSHKey}
          onSubmit={handleSSHKeySubmit}
        />
      </Modal>
      <Modal open={openDelete} setOpen={setOpenDelete} title="WARNING">
        <Alert setOpen={setOpenDelete} description={`Are you sure you want to delete your SSH Key: ${sshKeyToDelete && sshKeyToDelete.name ? sshKeyToDelete.name : ''}`} onSubmit={handleDeleteSSHKey} onCancel={handleCancelDeleteSSHKey} />
      </Modal>
      <Header title='SSH Keys'>
        <button
          type="button"
          className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={handleAddSSHKey}
        >
          <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          Add SSH Key
        </button>
      </Header>
      <div className="mt-5 mb-5">
        {sshKeys && sshKeys.length > 0 ? <SSHKeyList sshKeys={sshKeys} handleEditSSHKey={handleEditSSHKey} handleOpenDelete={handleOpenDelete} /> : <h3 className="text-sm font-bold text-indigo-600">You currently don't have any SSH Keys stored in Portkey</h3>}
      </div>
    </>
  )
}


const SSHKeyList = ({ sshKeys, handleEditSSHKey, handleOpenDelete }) => (
  <div className="bg-white shadow overflow-hidden sm:rounded-md">
    <ul className="divide-y divide-gray-200">
      {sshKeys.map((sk) => (
        <li key={sk.sshKeyId}>
          <SSHKey
            sshKey={sk}
            onEdit={() => handleEditSSHKey(sk)}
            onDelete={() => handleOpenDelete(sk)}
          />
        </li>

      ))}
    </ul>
  </div>
)