// Utils
import { capitalizeFirstLetter } from '../../util'

const InputWithLabel = ({name, label=capitalizeFirstLetter(name), placeholder=name, id=name, value, onChange}) => {
  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1">
        <input
          type="text"
          name={name}
          id={id}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default InputWithLabel
