const backendUrl = process.env.REACT_APP_API_URL

export const getNameFromTags = tags => {
  if (tags && tags.length > 0) {
    const nameTag = tags.find(tag => tag.key === "Name" || tag.key === "name");
    if (nameTag && nameTag.value) {
      return nameTag.value
    }
  }
  return ""
}

export const filterResourcesByVpcId = (resources, vpcId) => {
  const resourceTypes = Object.keys(resources)
  const newResources = {}
  resourceTypes.forEach(type => {
    newResources[type] = resources[type].filter(r => r.vpcId === vpcId)
    if (newResources[type].length === 0) {
      delete newResources[type]
    }
  })
  return newResources
}

export const getDefaultSubnet = (subnets) => {
  const index = subnets.findIndex(subnet => {
    if (subnet.tags && subnet.tags.length > 0) {
      return subnet.tags.find(t => t.key === "Name" && t.value.includes("dmz01"))
    }
    return false
  })
  return index === -1 ? subnets[0] : subnets[index]
}

export const getDefaultSSHKey = (sshKeys) => {
  if (sshKeys && sshKeys.length > 0) {
    return sshKeys.find(key => key.isDefault)
  }
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const parseCheckedResources = (resources) => {
  const result = {}
  resources.forEach(resource => {
    if (result[resource.resourceType]) {
      result[resource.resourceType].push(resource)
    } else {
      result[resource.resourceType] = [resource]
    }
  })
  return result
}
export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

export const wait = ms => {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      resolve(`${ms} ms passed`);
    }, ms);
  });
}

export const fetchGET = async (path, token) => {
  const response = await fetch(`${backendUrl}/${path}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Access-Control-Request-Method': 'GET, OPTIONS',
    }
  })
  return await response.json();
}

export const fetchPOST = async (path, token, body) => {
  const response = await fetch(`${backendUrl}/${path}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Access-Control-Request-Method': 'POST, OPTIONS',
    },
    method: 'POST',
    body: JSON.stringify(body)
  })
  return await response.json();
}

export const fetchPATCH = async (path, token, body) => {
  const response = await fetch(`${backendUrl}/${path}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Access-Control-Request-Method': 'PATCH, OPTIONS',
    },
    method: 'PATCH',
    body: JSON.stringify(body)
  })
  return await response.json();
}

export const fetchDELETE = async (path, token) => {
  const response = await fetch(`${backendUrl}/${path}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Access-Control-Request-Method': 'DELETE, OPTIONS',
    },
    method: 'DELETE'
  })
  return await response.json();
}