import { ChipIcon, CubeIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom';

export default function BastionList({ bastions }) {
  if (bastions && bastions.length > 0) {
    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          {bastions.map((bastion) => (
            <li key={bastion.id}>
              <Link to={`bastions/${bastion.id}`} className=" block hover:bg-gray-50">
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-medium text-indigo-600">{bastion.name}</p>
                  </div>
                  <div className="mt-2 flex justify-between items-center">
                    <p className="flex items-center text-sm text-gray-500">
                      <ChipIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      {bastion.id}
                    </p>
                    <div className="flex items-center text-sm text-gray-500">
                      <CubeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <p>
                        {bastion.accountNumber}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }
  return <h3 className="text-sm font-bold text-indigo-600">No Running Bastions</h3>
}