// Utils
import { getNameFromTags } from "../util";

export default function ResourcesList({ resources }) {
  if (resources && resources.length > 0) {
    return (
      <div className={resources.length > 1 ? "bg-white overflow-hidden border-t border-b border-gray-200" : ""}>
        <ul className="divide-y divide-gray-200">
          {resources.map((r) => (
            <li key={r.id} className="py-1">
              <div className="mx-3">
                <div className="flex items-center justify-between">
                  <p className="text-xs font-medium text-gray-900">{r.id}</p>
                  <div className="ml-2 flex-shrink-0 flex">
                    <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      {r.resourceType}
                    </p>
                  </div>
                </div>
                <p className="text-xs text-gray-500">{getNameFromTags(r.tags)}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    )
  }
  return <></>
}
