// React
import { useEffect, useState } from 'react'
// Auth
import { useAuth0 } from '@auth0/auth0-react';
// Store
import { useStateValue } from '../store/StateProvider';
import { SET_BASTIONS } from '../store/actions'
// Utils
import { fetchGET } from '../util'
// Components
import Loading from '../components/Loading'
import BastionList from '../components/BastionList'
import Header from '../components/Header'
import Error from '../components/Error'

const Bastions = () => {
  // Auth
  const { user } = useAuth0()
  // Store
  const [{ bastions, token }, dispatch] = useStateValue();
  // State
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  // Fetch Bastions
  useEffect(() => {
    const fetchBastions = async () => {
      setIsLoading(true)
      try {
        const data = await fetchGET(`users/${user.email}/bastions`, token)
        dispatch({
          type: SET_BASTIONS,
          payload: data
        })
      } catch (err) {
        console.log("ERROR: ", err)
        setError("Failed to Fetch")
      }
      setIsLoading(false)
    }
    fetchBastions()
  }, [dispatch, token, user.email])

  // if Loading
  if (isLoading) return <Loading />
  // If Error
  if (error) return <Error error={error} />
  return (
    <>
      <Header title='Bastions'/>
      <div className="mt-5 mb-5">
        <BastionList bastions={bastions} />
      </div>
    </>
  )
}

export default Bastions
