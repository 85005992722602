import { UserIcon, CubeIcon } from "@heroicons/react/solid"
import { Link } from "react-router-dom"

export default function AccountList({ accounts }) {
  if (accounts.error) {
    return (
      <>
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div
            class="flex bg-red-100 rounded-lg p-4 mb-4 text-sm text-red-700"
            role="alert"
          >
            <svg
              class="w-5 h-5 inline mr-3"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <div>
              <span class="font-medium">Something went wrong</span> We couldn't
              get the list of your AWS accounts. Try again later.
              <details>
                <summary>Details of Error</summary>
                <code>{accounts.error}</code>
              </details>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {accounts.map((acc) => (
          <li key={acc.accountId}>
            <Link
              to={`accounts/${acc.accountId}`}
              className=" block hover:bg-gray-50"
            >
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-indigo-600">
                    {acc.accountName}
                  </p>
                  <div className="ml-2 flex-shrink-0 flex">
                    <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      {acc.departmentName}
                    </p>
                  </div>
                </div>
                <div className="mt-2 flex justify-between items-center">
                  <p className="flex items-center text-sm text-gray-500">
                    <CubeIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {acc.accountId}
                  </p>
                  <div className="flex items-center text-sm text-gray-500">
                    <UserIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <p>{acc.ownerName}</p>
                  </div>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
