// React
import { useEffect, useState } from "react"
// Auth
import { useAuth0 } from "@auth0/auth0-react"
// Store
import { useStateValue } from "../store/StateProvider"
import { SET_ACCOUNTS } from "../store/actions"
// Utils
import { fetchGET } from "../util"
// Components
import Loading from "../components/Loading"
import AccountList from "../components/AccountList"
import Header from "../components/Header"
import Error from "../components/Error"

const Accounts = () => {
  // Store
  const [{ accounts, token }, dispatch] = useStateValue()
  // Auth
  const { user } = useAuth0()
  // State
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [filteredAccounts, setFilteredAccounts] = useState(accounts)

  // Fetch Accounts
  useEffect(() => {
    const fetchAccounts = async () => {
      setIsLoading(true)
      try {
        const data = await fetchGET(`users/${user.email}/accounts`, token)
        dispatch({
          type: SET_ACCOUNTS,
          payload: data.sort((a, b) =>
            a.accountName.localeCompare(b.accountName)
          ),
        })
      } catch (err) {
        console.log("ERROR: ", err)
        setError("Failed to fetch")
      }
      setIsLoading(false)
    }
    fetchAccounts()
  }, [dispatch, token, user.email])

  useEffect(() => {
    setFilteredAccounts(accounts)
  }, [accounts])

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase()
    const newFilteredAccounts = accounts.filter((acc) => {
      if (
        acc.accountName.toLowerCase().includes(value) ||
        acc.accountId.toLowerCase().includes(value)
      ) {
        return acc
      }
      return null
    })
    setFilteredAccounts(newFilteredAccounts)
  }
  // If Error
  if (error) return <Error error={error} />

  // if Loading
  if (isLoading) return <Loading />

  return (
    <>
      <Header title="Accounts" handleSearch={handleSearch} />
      <div className="mt-5 mb-5">
        <AccountList accounts={filteredAccounts} />
      </div>
    </>
  )
}

export default Accounts
