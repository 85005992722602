
import { useState } from 'react'
import SideNav from './SideNav'
import StaticNav from './StaticNav'
import SmallNav from './SmallNav'


export default function Layout({children}) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  return (
    <div className="relative h-screen flex overflow-hidden bg-white">
      <SideNav setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
      <StaticNav />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <SmallNav setSidebarOpen={setSidebarOpen} />
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          {children}
        </main>
      </div>
    </div>
  )
}


