import { RadioGroup } from '@headlessui/react'
// Utils
import { getNameFromTags, classNames } from '../../util'

export default function VpcsRadio({vpcs, selected, onChange}) {
  return (
    <RadioGroup value={selected} onChange={onChange}>
      <RadioGroup.Label className="sr-only">VPC List</RadioGroup.Label>
      <div className="bg-white rounded-md -space-y-px">
        {vpcs.map((vpc, vpcIdx) => (
          <RadioGroup.Option
            key={vpc.id}
            value={vpc}
            className={({ checked }) =>
              classNames(
                vpcIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                vpcIdx === vpcs.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                checked ? 'bg-green-50 border-green-200 z-10' : 'border-gray-200',
                'relative border p-2 flex cursor-pointer focus:outline-none'
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked ? 'bg-green-600 border-transparent' : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-green-500' : '',
                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(checked ? 'text-green-900' : 'text-gray-900', 'block text-sm font-medium')}
                  >
                    {vpc.id}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={classNames(checked ? 'text-green-700' : 'text-gray-500', 'block text-sm')}
                  >
                    {getNameFromTags(vpc.tags)}
                  </RadioGroup.Description>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}