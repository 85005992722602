import SearchInput from './Form/SearchInput'

const Header = ({title, handleSearch, children}) => {
  return (
    <div className="flex content-between items-center">
      <h1 className="text-3xl font-semibold mr-2 flex-grow">{title}</h1>
      {handleSearch ? <SearchInput onChange={handleSearch} /> : ''}
      {children}
    </div>
  )
}
export default Header