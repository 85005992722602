
/* This example requires Tailwind CSS v2.0+ */
import { useState, useEffect } from 'react'
import { ExclamationIcon } from '@heroicons/react/outline'
import { useHistory } from 'react-router-dom';

export default function Error({ error, title = 'ERROR', path = '/', buttonText = 'Continue' }) {
  // History
  const history = useHistory()
  const [errMSG, setErrMSG] = useState('Error')

  useEffect(() => {
    if (error.message) {
      setErrMSG(error.message)
    } else if (typeof error === "string") {
      setErrMSG(error)
    } else if (typeof error === "object") {
      setErrMSG(JSON.stringify(error, null, 2))
    }
  }, [error])

  const hanldeClick = () => {
    history.push(path)
  }
  return (
    <div className='flex-1 w-full h-full flex justify-center items-center'>
      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {errMSG}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
            onClick={hanldeClick}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  )
}


