import React from 'react'
import Logo from './Logo'
import Links from './Links'
import Avatar from './Avatar'
// Auth
import { useAuth0 } from '@auth0/auth0-react'

const StaticNav = () => {
  const { user } = useAuth0();
  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-64 border-r border-gray-200 pt-5 pb-4 bg-gray-800 text-white">
        <div className="flex items-center flex-shrink-0 px-6">
          <Logo />
        </div>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="h-0 flex-1 flex flex-col overflow-y-auto">
          <div className="px-3 mt-3 relative inline-block text-left">
            <span className="flex w-full justify-between items-center px-3.5 py-2">
              <span className="flex min-w-0 items-center justify-between space-x-3">
                <Avatar src={user.picture} size='10'/>
                <span className="flex-1 flex flex-col min-w-0">
                  <span className="text-gray-300 text-sm font-medium truncate">{user.given_name} {user.family_name}</span>
                  <span className="text-gray-400 text-sm truncate">@{user.nickname}</span>
                </span>
              </span>
            </span>
          </div>
          {/* Navigation */}
          <nav className="px-3 mt-3">
            <div className="space-y-1">
              <Links />
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default StaticNav
