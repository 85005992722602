
import { TrashIcon, PencilAltIcon } from '@heroicons/react/outline'

export default function SSHKey({ sshKey, onEdit, onDelete, actionButtons = 'true' }) {
  return (
    <div className="px-4 py-4 sm:px-6">
      <div className="flex items-center justify-between">
        <p className="text-sm font-medium text-indigo-600 truncate">{sshKey.name}</p>
        {sshKey.isDefault ? (<p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          Default
        </p>) : ''}
        {actionButtons ?
          <div className="ml-2 flex-shrink-0 flex">
            <button className="mr-1" onClick={onEdit}>
              <PencilAltIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400 hover:text-indigo-400" aria-hidden="true" />
            </button>
            <button onClick={onDelete}>
              <TrashIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400 hover:text-indigo-400" aria-hidden="true" />
            </button>
          </div> : ''
        }
      </div>
      <div className="mt-2">
        <p className="text-sm text-gray-500 break-all">
          {sshKey.value}
        </p>
      </div>
    </div>
  )
}
