import { Fragment } from 'react'
// Auth
import { useAuth0 } from '@auth0/auth0-react'
import { Menu, Transition } from '@headlessui/react'
import { MenuAlt1Icon } from '@heroicons/react/outline'
import Logo from './Logo'
// import Avatar from './Avatar'

const SmallNav = ({ setSidebarOpen }) => {
  const { logout, user } = useAuth0();

  return (
    <div className="relative z-10 flex-shrink-0 flex h-16 bg-gray-800 text-white border-b border-gray-200 md:hidden">
      <button
        type="button"
        className="px-4 text-gray-400 focus:outline-none lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex-1 flex justify-between px-4 sm:px-6 lg:px-8 items-center">
        <Logo />

        <div className="flex items-center">
          {/* Profile dropdown */}
          <Menu as="div" className="ml-3 relative">
            <div>
              <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                <span className="sr-only">Open user menu</span>
                <img
                  className="h-8 w-8 rounded-full"
                  src={user.picture}
                  alt="avatar"
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none overflow-hidden">
                <Menu.Item>
                  <span className="block px-4 py-2 text-sm text-gray-700 font-bold">{user.nickname}</span>
                </Menu.Item>
                <Menu.Item>
                  <button
                    className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left'
                    onClick={logout}
                  >
                    Logout
                  </button>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  )
}

export default SmallNav
