// React
import { NavLink } from 'react-router-dom'
// Icons
import { HomeIcon, CubeIcon, ChipIcon, KeyIcon, LogoutIcon } from '@heroicons/react/outline'
// Auth
import { useAuth0 } from '@auth0/auth0-react'

const navigation = [
  { name: 'Dashboard', href: '/', icon: HomeIcon },
  { name: 'Accounts', href: '/accounts', icon: CubeIcon },
  { name: 'Bastions', href: '/bastions', icon: ChipIcon },
  { name: 'SSH Keys', href: '/sshkeys', icon: KeyIcon },
]


const Links = () => {
  const { logout } = useAuth0();
  return (
    <>
      {navigation.map((item) => (<Link item={item} />))}
      <button
        type="button"
        className="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md w-full"
        onClick={logout}
      >
        <LogoutIcon className="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
        Logout
      </button>
    </>
  )
}

export default Links

const Link = ({ item }) => (
  <NavLink
    key={item.name}
    to={item.href} exact
    className="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md"
    activeClassName="bg-gray-900 text-white group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md"
  >
    <item.icon
      className="mr-3 flex-shrink-0 h-6 w-6"
      aria-hidden="true"
    />
    {item.name}
  </NavLink>
)