// React
import { useState, useEffect } from 'react'
// import { useHistory } from 'react-router-dom';
// Icons
import { PlusIcon } from '@heroicons/react/solid'
// Auth
import { useAuth0 } from '@auth0/auth0-react';
// Store
import { useStateValue } from '../../store/StateProvider'
import { SET_SSHKEYS } from '../../store/actions'
// Utils
// import { getDefaultSSHKey, getDefaultSubnet, getNameFromTags, parseCheckedResources, wait, fetchPOST, fetchGET } from '../../util'
import { getDefaultSSHKey, getDefaultSubnet, getNameFromTags, parseCheckedResources, fetchPOST, fetchGET } from '../../util'
// Components
import SubnetDropdown from './SubnetDropdown'
import SSHKeyDropdown from './SSHKeyDropdown'
import SSHKeyInput from './SSHKeyInput'
import UpdateSSHKey from './UpdateSSHKey'
import ResourcesList from '../ResouresList'

const CreateBastion = ({ vpc, resources, onSubmit, account }) => {
  // Auth
  const { user } = useAuth0()
  // History
  // const history = useHistory()
  // Store
  const [{ sshKeys, token }, dispatch] = useStateValue()
  // State
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [selectedSubnet, setSelectedSubnet] = useState(getDefaultSubnet(vpc.subnets))
  const [selectedSSHKey, setSelectedSSHKey] = useState(getDefaultSSHKey(sshKeys))
  const [sshKeyValue, setSSHKeyValue] = useState("")
  const [openAddSSHKey, setOpenAddSSHKey] = useState(false)

  useEffect(() => {
    setSelectedSSHKey(getDefaultSSHKey(sshKeys))
  }, [sshKeys])

  // Handle SSH Key Value Change
  const handleSSHKeyValueChange = (e) => {
    setSSHKeyValue(e.target.value)
  }
  // Toggle Open Add SSH Key
  const toggleOpenAddSSHKey = () => {
    setOpenAddSSHKey(!openAddSSHKey)
  }
  // Handle Add SSH Key
  const handleAddSSHKeySubmit = async ({ name, value, isDefault }) => {
    setIsLoading(true)
    try {
      const body = { name, value, isDefault }
      await fetchPOST(`users/${user.email}/sshkeys`, token, body)
      const newKeys = await fetchGET(`users/${user.email}/sshkeys`, token)
      dispatch({
        type: SET_SSHKEYS,
        payload: newKeys
      })
      setSSHKeyValue('')
      setOpenAddSSHKey(false)
    } catch (err) {
      console.log("ERROR: ", err)
      setError(err)
    }
    setIsLoading(false)

  }
  const handleCreateBastion = () => {
    const body = {
      accountNumber: account.accountId,
      publicKey: selectedSSHKey ? selectedSSHKey.value : sshKeyValue,
      resources: parseCheckedResources(resources),
      vpcId: vpc.id,
      subnetId: selectedSubnet.id,
      userId: user.email,
      username: user.nickname
    }
    onSubmit(body)
  }
  
  if (error) return <h1>Error: {error}</h1>
  if (isLoading) return (
    <div className="flex flex-col justify-center content-center items-center">
      <h1 className="text-4xl font-bold text-red-600">PORT<span className="text-gray-900"> | KEY</span></h1>
      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-20 w-20 m-20"></div>
      <h2 className="text-center text-xl font-semibold">Loading...</h2>
    </div>
  )
  if (openAddSSHKey) return (
    <UpdateSSHKey
      value={sshKeyValue}
      title='Add SSH Key'
      onCancel={() => setOpenAddSSHKey(false)}
      onSubmit={handleAddSSHKeySubmit}
    />
  )

  return (
    <>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Resources
        </h3>
        <ResourcesList resources={resources} />
        <h3 className="mt-2 text-lg leading-6 font-medium text-gray-900">
          VPC
        </h3>
        <div className="mx-3">
          <p className="text-xs font-medium text-gray-900">{vpc.id}</p>
          <p className="text-xs text-gray-500">{getNameFromTags(vpc.tags)}</p>
        </div>
        <h3 className="mt-2 text-lg leading-6 font-medium text-gray-900">
          Subnet
        </h3>
        <SubnetDropdown subnets={vpc.subnets} selected={selectedSubnet} setSelected={setSelectedSubnet} />
        <div className="mt-2 flex content-between">
          <h3 className="text-lg leading-6 font-medium text-gray-900 flex-grow">
            SSH Key
          </h3>
          {sshKeys && sshKeys.length > 0 ?
            <button
              type="button"
              className="inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={toggleOpenAddSSHKey}
            >
              <PlusIcon className="-ml-0.5 mr-1 h-4 w-4" aria-hidden="true" />
              SSH Key
            </button>
            : ''
          }
        </div>
        {sshKeys && sshKeys.length > 0 ?
          <SSHKeyDropdown
            sshKeys={sshKeys}
            selected={selectedSSHKey}
            setSelected={setSelectedSSHKey}
          /> :
          <SSHKeyInput
            value={sshKeyValue}
            onChange={handleSSHKeyValueChange}
            onSave={toggleOpenAddSSHKey}
          />
        }
      </div>
      <div className="mt-5 sm:mt-6">
        <button
          type="button"
          className={resources && resources.length > 0 && vpc && selectedSubnet && (selectedSSHKey || sshKeyValue) ?
            "inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm" :
            "inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 text-base font-medium text-white sm:text-sm cursor-default"
          }
          onClick={handleCreateBastion}
          disabled={resources && resources.length > 0 && vpc && selectedSubnet && (selectedSSHKey || sshKeyValue) ? false : true}
        >
          Create Bastion
        </button>
      </div>
    </>
  )
}

export default CreateBastion
